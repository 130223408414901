import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { statisticAPI } from "api/stats-api";
import cn from "classnames";

import {
  setPopupStateAC,
  setRedirectItemAC,
  setSidebarBlockStatusAC,
  triggerRedirectAC,
} from "actions-thunks/actions";
import { authThunks } from "actions-thunks/auth-thunks";

import "./main-taskbar.scss";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

const MainTaskbar = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { currentAccount } = useSelector((state) => state.accounts);
  const { triggerRedirect, currentPageTitle, sidebarBlockCollapsed } =
    useSelector((state) => state.utils);
  const { profileData, burgerMenuOpened, fieldIsEditing } = useSelector(
    (state) => state.profile
  );

  const blockRef = useRef(null);
  const menuBlockRef = useRef(null);

  const [isToggled, setIsToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogoutClick = () => {
    currentAccount?._id &&
      statisticAPI.sendStatUsage(currentAccount._id, { page: `Log Out` });
    if (fieldIsEditing.status) {
      dispatch(
        setPopupStateAC({
          opened: true,
          message:
            "Are you sure you want to logout without saving the changes ?",
          type: "saves",
        })
      );
      dispatch(setRedirectItemAC({ type: "logout", item: null }));
    } else {
      dispatch(authThunks.logoutTC());
    }
  };

  const handleClickOutside = (event) => {
    if (
      blockRef.current &&
      !blockRef.current.contains(event.target) &&
      menuBlockRef.current &&
      !menuBlockRef.current.contains(event.target) &&
      isToggled
    ) {
      onDropdownToggleClick();
    }
  };

  useEffect(() => {
    if (isToggled) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggled]);

  useEffect(() => {
    if (triggerRedirect.status) {
      navigate(triggerRedirect.direction);
      dispatch(triggerRedirectAC({ direction: "", status: false }));
    }
  }, [triggerRedirect, navigate, dispatch]);

  const onDropdownToggleClick = (e) => {
    if (!!isToggled) {
      setIsToggled(false);
    } else {
      setIsToggled(e);
    }
  };

  const onBurgerMenuClick = () => {
    dispatch(setSidebarBlockStatusAC(!sidebarBlockCollapsed));
  };

  const setDateFormat = useMemo(() => {
    if (currentAccount)
      return currentAccount.updatedAt
        ? utilityFunctions.transformToStepDateView(currentAccount.updatedAt)
        : "N/A";
  }, [currentAccount]);

  const firstname = profileData?.firstName || "";
  const lastname = profileData?.lastName || "";

  const accountTypeValue = currentAccount?.accountType
    ?.replace("zimtra", "")
    ?.replace("expert", "intermediate");

  const formattedAccountType = accountTypeValue
    ? accountTypeValue.charAt(0).toUpperCase() + accountTypeValue.slice(1)
    : "";

  const isSimulator =
    !formattedAccountType && currentAccount?.softwareType === "Sim"
      ? "Simulator"
      : "";

  return (
    <div className="taskbar-block" data-testid="main-taskbar">
      <div className="taskbar-container">
        {isAuthenticated && (
          <img
            onClick={onBurgerMenuClick}
            className="menu-icon"
            width={24}
            height={24}
            src={`${process.env.PUBLIC_URL}/${
              burgerMenuOpened ? "x" : "menu"
            }-icon.svg`}
            alt="menu-icon"
          />
        )}
        <div className="taskbar-title-block">
          {currentPageTitle && (
            <h3 className="taskbar-page-title">{currentPageTitle}</h3>
          )}
          <span>updated on {setDateFormat}</span>
        </div>
        <div
          className={cn(
            "taskbar-collapse-block",
            `${accountTypeValue || "sim"}-acc`
          )}
        >
          <div
            className="taskbar-collapse-block-item"
            ref={blockRef}
            onClick={onDropdownToggleClick}
          >
            <div className="taskbar-avatar">
              <div className="taskbar-avatar-letters">
                {firstname[0] && `${firstname[0]}${lastname[0]}`.toUpperCase()}
              </div>
            </div>
            <span>
              {formattedAccountType}
              {isSimulator}
            </span>
          </div>
          {isToggled && (
            <div
              className="menu-block"
              onClick={onLogoutClick}
              ref={menuBlockRef}
            >
              <img
                width="24"
                height="24"
                src={`${process.env.PUBLIC_URL}/cabinet-logout.svg`}
                alt="logout profile"
              />
              Logout
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainTaskbar;
